import V from 'voUtils/V.js';
import MarkerClusterer from 'voUtils/MarkerClusterer.js';
import {
	debounce
}
from 'voUtils/tools.js';

import {
	loadGmaps
}
from 'voUtils/loader.js';


function pinSymbol(color) {
	return {
		path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
		fillColor: color,
		fillOpacity: 1,
		strokeColor: '#000',
		strokeWeight: 1,
		scale: 1.2
	};
}

const DEFAULT_MARKER_COLOR = '#3548a1';


export default {
	voVueComponent: 'voffice-gmap',
	template: `
<div>
	<div v-if="canShowGoogleMaps()">
      <div class="mapnote">
          {{voMsg('google.map.note1')}}
          <voffice-doc-box doc-id="privacy">
              <a class="footer-link"
                  href="">{{voMsg('google.map.note.datenschutz')}}</a>
          </voffice-doc-box> {{voMsg('google.map.note2')}}
          <br><a href=""
              @click.prevent="preventGoogleMaps()">{{voMsg('google.map.note.prevent')}}</a>
      </div>
     <div ref="slot">
        <slot></slot>
     </div>
     <div style="width:100%;height:100%" ref="map">
     </div>
	</div>
	<div v-else>
      <div class="mapconsent">
        <p>{{voMsg('google.map.consent.note1')}}
    <br>{{voMsg('google.map.consent.note2')}}</p>
    <voffice-doc-box doc-id="privacy">
        <a class="footer-link mapconsent-button"
            href="">{{voMsg('google.map.note.datenschutz')}}</a>
    </voffice-doc-box>
    <br><a href="" 
		  class="mapconsent-button"
        @click.prevent="allowGoogleMaps();">{{voMsg('google.map.allow.link')}}</a>
       </div>
	</div>
</div>
`,
	props: {
		items: Array,
		initialPos: Array,
		initialZoom: Number,
		mapType: String,
		scrollok: Boolean,
		draggable: String,
		highlight: Number,
		selected: Object,
		markerPos: Array,
		cluster: Boolean
	},
	created: function () {

		var vc = this;
		this.onMarkerClicked = function () {
			vc.$emit('update:selected', this.customData);
		};

		var onResize = debounce(() => {
			this.updateBounds();
		}, 800);
		window.addEventListener("resize", onResize);

	},
	mounted: function () {

		var initialPos = this.initialPos || this.markerPos || [10, 54];
		var initialZoom = this.initialZoom || 5;
		if (this.canShowGoogleMaps()) {

			loadGmaps(() => {

				var myStyles = [{
					featureType: "poi.business",
					elementType: "labels",
					stylers: [{
						visibility: "off"
					}]
				}];

				var mapOptions = {
					clickableIcons: false,
					center: new google.maps.LatLng(initialPos[1], initialPos[0]),
					zoom: initialZoom,
					mapTypeId: google.maps.MapTypeId[this.mapType || 'ROADMAP'],
					styles: myStyles
				};

				if (!this.scrollok) {
					mapOptions.scrollwheel = false;
				}

				if (this.draggable === 'false') {
					mapOptions.draggable = false;
				}

				var map = new google.maps.Map(this.$refs.map, mapOptions);
				this.map = map;

				google.maps.event.addListener(map, 'zoom_changed', function () {
					//let zoomChangeBoundsListener = google.maps.event.addListener(map, 'bounds_changed', function (event) {
					if (this.getZoom() > 15 && this.initialZoom == true) {
						// Change max/min zoom here
						this.setZoom(15);
						this.initialZoom = false;
					}
					//google.maps.event.removeListener(zoomChangeBoundsListener);
					//});
				});

				this.infowindow = new google.maps.InfoWindow({
					content: this.$refs.slot,
					maxWidth: 320
				});

				google.maps.event.addListener(this.infowindow, 'closeclick', () => {
					V.log("infowindow closed!");
					this.$emit('update:selected', {});
				});


				if (this.lazyUpdateMarkers) {
					this.updateMarkers();
				}
				if (this.lazyUpdateSingleMarker) {
					this.updateSingleMarker();
				}

				/*
			if (attrs.pos) {
				showMarker(initialPos);
			} else if (toShowList) {
				showList(toShowList);
			} else if (staticMarkers) {
				showStaticMarkers();
			}
         */


			});


		}
	},

	methods: {
		allowGoogleMaps: function () {
			localStorage.setItem('allowGoogleMaps', true);
			location.reload();
		},
		preventGoogleMaps: function () {
			localStorage.setItem('allowGoogleMaps', false);
			location.reload();
		},
		canShowGoogleMaps: function () {
			var GoogleMaps = localStorage.getItem("allowGoogleMaps");
			if (GoogleMaps === 'true') {
				return true;
			}
			return false;
		},
		findMarkerById: function (id) {
			if (this.markers) {
				for (let m of this.markers) {
					if (m.customData && m.customData._id == id) {
						return m;
					}
				}
			}
		},
		updateBounds: function () {
			if (this.bounds && this.markers && this.markers.length) {

				this.map.initialZoom = true;
				this.map.fitBounds(this.bounds);
			}
		},
		deleteMarkers: function () {
			if (this.markers) {

				if (this.clusterer) {
					this.clusterer.removeMarkers(this.markers);
				}


				for (let m of this.markers) {
					m.setMap(null);
				}
			}

			this.markers = [];

		},

		updateSingleMarker: function () {

			if (this.map) {


				this.deleteMarkers();

				if (this.markerPos) {
					let latlng = new google.maps.LatLng(this.markerPos[1], this.markerPos[0]);

					let markerOptions = {
						position: latlng,
						map: this.map,
						draggable: false
					};

					let marker = new google.maps.Marker(markerOptions);
					this.markers.push(marker);
				}

			} else {
				this.lazyUpdateSingleMarker = true;
			}

		},

		updateMarkers: function () {

			if (this.map) {

				this.deleteMarkers();

				V.log("gmap items", this.items);

				if (this.items) {
					this.bounds = new google.maps.LatLngBounds();

					for (let item of this.items) {
						let markerOptions;

						if (item.loc) {
							let latlng = new google.maps.LatLng(item.loc.coordinates[1], item.loc.coordinates[0]);

							markerOptions = {
								position: latlng,
								icon: pinSymbol(DEFAULT_MARKER_COLOR),
								draggable: false,
								customData: item
							};

						} else if (Array.isArray(item)) {
							//array result
							let latlng = new google.maps.LatLng(item[1], item[0]);

							markerOptions = {
								position: latlng,
								icon: pinSymbol(DEFAULT_MARKER_COLOR),
								draggable: false,
								customData: {
									_id: item[2],
									lazy: true,
									info: item
								}
							};
						}

						if (markerOptions) {


							if (!this.cluster) {
								markerOptions.map = this.map;
							}

							let marker = new google.maps.Marker(markerOptions);

							marker.addListener('click', this.onMarkerClicked);
							this.markers.push(marker);
							this.bounds.extend(marker.getPosition());

						}

					}

					this.updateBounds();

					if (this.cluster) {
						if (this.clusterer) {
							this.clusterer.addMarkers(this.markers);
						} else {
							this.clusterer = new MarkerClusterer(this.map, this.markers, {
								imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
								maxZoom: 15
							});
						}
					}

				}

			} else {
				this.lazyUpdateMarkers = true;
			}

		},
		unHighlightMarker: function (m) {
			if (this.highlightedMarker) {
				m.setAnimation(null);
				m.setIcon(pinSymbol(DEFAULT_MARKER_COLOR));
				m.setZIndex(m._zIndex);

				this.highlightedMarker = undefined;
			}
		},
		highlightMarker: function (m) {
			m.setAnimation(google.maps.Animation.BOUNCE);
			m.setIcon(pinSymbol('red'));

			m._zIndex = m.getZIndex();
			m.setZIndex(2000);
			this.highlightedMarker = m;
			setTimeout(() => {
				m.setAnimation(null);
			}, 750);
		}
	},
	watch: {
		markerPos: function () {
			this.updateSingleMarker();
		},
		items: {
			deep: true,
			handler: function (nv) {
				this.updateMarkers();
			}
		},
		highlight: function (id) {

			if (this.selected && this.selected._id) {
				return;
			}

			this.updateBounds();

			this.unHighlightMarker(this.highlightedMarker);

			if (id) {
				let m = this.findMarkerById(id);
				if (m) {
					this.highlightMarker(m);

				}
			}
		},
		selected: function (nv) {
			this.unHighlightMarker(this.highlightedMarker);
			if (!this.cluster) {
				this.updateBounds();
			}

			var m = this.findMarkerById(nv._id);
			if (m) {
				this.infowindow.open(this.map, m);
				this.highlightMarker(m);
			} else {
				this.infowindow.close();
			}

		}

	}
};